import { useMemo, useContext, useState, useEffect, useCallback, useRef, ChangeEvent, ReactNode } from 'react';
import { Box } from 'aws-northstar';
import Amplify, { I18n, Logger , API} from 'aws-amplify'
import StatusIndicator from 'aws-northstar/components/StatusIndicator';


export const UpdatesVisualizePage: React.FC = () => {
    
    const logger = new Logger('UpdatesVisualizePage');

    const [random, setRandom] = useState(0);
    const [iframeUrl, setIframeUrl] = useState('');

    //副作用：API呼び出し。コンポーネントマウント後に実行(初期画面レンダリング後に実行)
    useEffect(() => {

        console.info('useEffect called.');
        fetchDashboardEmbedUrl();

    }, []);

    //useEffectで実行されるもの
    const fetchDashboardEmbedUrl = useCallback(() => {
        console.info('fetchDashboardEmbedUrl called.');
        (async () => {
            try {
                const resp = await API.get('GetQuickSightDashboardEmbedUrlAPI','/Prod/quicksightdashboardurl', {});
                //console.log(resp);
                setIframeUrl(resp.EmbedUrl);
            } catch (err) {
                console.error(err);
            }
        })();
    }, []);

    return (
        //奇妙に見えるが、以下の"<>…</>は、JSXの「トップ要素は1つだけ」を満たすための頻出テクニック
            <Box width='100%' height='550px'>
                <iframe title="quicksight-dashboard" width="100%" height="100%" frameBorder="0" key={random} src={iframeUrl}></iframe>
            </Box>
    );

}