
import Table, { Column } from 'aws-northstar/components/Table';
import { useMemo, useContext, useState, useEffect, useCallback, useRef, ChangeEvent, ReactNode } from 'react';
import Amplify, { I18n, Logger } from 'aws-amplify'
import StatusIndicator from 'aws-northstar/components/StatusIndicator';
import Select, { SelectOption } from 'aws-northstar/components/Select';
import Link from 'aws-northstar/components/Link';
import Icon from 'aws-northstar/components/Icon';
import Text from 'aws-northstar/components/Text';
import FormField from 'aws-northstar/components/FormField'
import Flashbar, { FlashbarMessage } from 'aws-northstar/components/Flashbar';
import Button from 'aws-northstar/components/Button';
import Inline from 'aws-northstar/layouts/Inline';
import { UpdatesService } from '../services/updates-service';
import { IUpdatesItem } from '../models/updates-item';
import { orderBy } from 'lodash';
import { AppContext, AppContextType } from '../components/app-context';
import ItemCategoryOptions, { getCategoryLabelFrom } from '../constants/item-categories';
/**
 * 
 */
export const UpdatesListPage: React.FC = () => {

    const logger = new Logger('UpdatesListPage');

    const [items, setItems] = useState<any>([]);
    const [rowCount, setRowCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectedItems, setSelectedItems] = useState<IUpdatesItem[]>([]);
    const [selectedItemCategory, setSelectedItemCategory] = useState('NONE');
    const fetchIdRef = useRef(0);
    const appContext: AppContextType = useContext(AppContext);

    //カテゴリ選択オプション状態
    const [selectedCategoryOption, setSelectedCategoryOption] = useState<SelectOption>();

    const columnDefinitions: Column<IUpdatesItem>[] = [
        {
            id: 'category',
            width: 140,
            Header: I18n.get('Category'),
            accessor: 'category',
            Cell: useCallback((row: any) => {
                //console.log(row.row)
                if (row.row && row.row.original) {
                    const category = row.row.original.category;
                    const predicted_category = row.row.original.predicted_category
                    const itemId: string = row.row.original.id as string;
                    if (category == 'NONE' && predicted_category == 'NONE') {
                        //return <StatusIndicator statusType='negative'>{getCategoryLabelFrom(category)} <Link href={'/items/' + itemId} underlineHover={false}><Icon name='Edit' fontSize='small' /></Link></StatusIndicator>;
                        return <StatusIndicator statusType='negative'>{getCategoryLabelFrom(category)} </StatusIndicator>;
                    } else if (category == 'NONE' && predicted_category != 'NONE') {
                        return <StatusIndicator statusType='warning'>{getCategoryLabelFrom(predicted_category)}</StatusIndicator>;
                    } else {
                        return <StatusIndicator statusType='positive'>{getCategoryLabelFrom(category)}</StatusIndicator>;
                    }
                }
                return 'NULL';
            }, [])
        },
        {
            id: 'published_date',
            width: 150,
            Header: I18n.get('Published Date'),
            accessor: 'published_date',
            Cell: useCallback((row: any) => {
                //console.log(row.row)
                if (row.row && row.row.original) {
                    //文字列として yyyy-mm-ddThh:mm:ss 形式文字列なので、'T'を' 'に置換して表示文字列としている
                    const published_date = row.row.original.published_date as string;
                    return published_date.replace('T', ' ');
                }
                return 'NULL';
            }, [])
        },
        {
            id: 'title_ja',
            width: 400,
            Header: I18n.get('Title'),
            accessor: 'title_ja'
        },
        {
            id: 'comment',
            width: 130,
            Header: I18n.get('Comment'),
            accessor: 'comment',
            Cell: useCallback((row: any) => {
                //console.log(row.row)
                if (row.row && row.row.original) {
                    const itemId: string = row.row.original.id as string;
                    if (row.row.original.comment) {
                        const comment = row.row.original.comment as string;
                        return <>{comment} <Link href={'/items/' + itemId} underlineHover={false}><Icon name='Edit' fontSize='small' /></Link></>;
                    } else {
                        return <>{I18n.get('(None)')} <Link href={'/items/' + itemId} underlineHover={false}><Icon name='Edit' fontSize='small' /></Link></>
                    }
                }
                return 'NULL';
            }, [])
        },
    ];

    //このUIでの更新データ
    let data: Array<any> = [];

    //useEffectで実行されるもの
    const fetchUpdatesItems = useCallback(() => {
        console.info('fetchUpdatesItems called.');
        let initialData: any = [];
        (async () => {
            try {
                //Updatesの一覧取得
                setLoading(true);
                initialData = await UpdatesService.listUpdates() as Array<IUpdatesItem>;
                //lodashユーティリティでOrderBy(降順ができるのはlodashの機能)
                initialData = orderBy(initialData, 'published_epochtime', 'desc');
                data = initialData;
                setItems(data);
                setRowCount(data.length);
                setLoading(false);
            } catch (err) {
                console.error(err);
                setLoading(false);
            }
        })();
    }, []);

    //
    const categoryChange = (event: any, child: ReactNode) => {
        setSelectedCategoryOption(ItemCategoryOptions.find((o: any) => (o.value === event.target.value)));
        console.info("selected Category Value:" + event.target.value);
        setSelectedItemCategory(event.target.value as string);
    };

    const setItemsWithNewCategory = useCallback(() => {
        console.info('setItemsWithNewCategory is called');
        const targetItemLength = selectedItems.length;
        //
    }, []);

    //副作用：API呼び出し。コンポーネントマウント後に実行(初期画面レンダリング後に実行)
    useEffect(() => {

        console.info('useEffect called.');
        fetchUpdatesItems();

    }, []);

    const tableActions = (
        <Inline>
            <Text variant='p'>カテゴリ設定：</Text>
            <Select
                placeholder="カテゴリーを選択"
                options={ItemCategoryOptions}
                selectedOption={selectedCategoryOption}
                onChange={categoryChange}
            ></Select>
            <Button variant='primary' onClick={setItemsWithNewCategory}>
                {I18n.get('Change Category')}
            </Button>

            <Button icon='refresh' onClick={() => fetchUpdatesItems()}>再読込</Button>
        </Inline>
    );

    /**
     * 
     * @param items 
     */
    const handleSelectionChange = useCallback((items: IUpdatesItem[]) => {
        console.log('handleSelectionChange is called.' + items.length);
        console.log('Selected Items count:' + items.length);
        setSelectedItems(items);
    }, []);

    /**
     * Tableコンポーネントの表示データフェッチ処理ハンドラ。onFetchDataで指定。
     * 「フィルタ入力欄」や歯車アイコンで、Tableコンポーネントの表示設定が変わったタイミングで毎回呼び出される。
     * この処理のが行われたあとで、テーブルはPageSizeとsetItemsされたデータ件数、Total行数踏まえて(再)描画する
     */
    const handleFetchData = useCallback((options) => {

        console.info('handleFetchData is called');

        setLoading(true);

        const fetchId = ++fetchIdRef.current;

        if (fetchId === fetchIdRef.current) {
            const filterData = data.filter((d: IUpdatesItem) => {
                if (options.filterText) {
                    //フィルタテキストが何かしら指定されている場合は、その文字列がtitle_jaに含まれているもののみをフィルタ
                    return d.title_ja.indexOf(options.filterText) > 0;
                }
                //filterTextが特に指定されていない場合は常にtrueを返す=対象に含める(除外しない)
                return true;
            });
            let tempData = filterData.slice(
                options.pageIndex * options.pageSize,
                (options.pageIndex + 1) * options.pageSize
            );
            if (options.sortBy && options.sortBy.length > 0) {
                //options.sortByオブジェクトは { id: string, desc: boolean } 型のオブジェクト
                //idフィールドは、UIで選択(クリック)された文字列としてのtempDataのフィールド名が入っている
                //descはUIで選択された昇順or降順の指定(そうせよという値)が入っている
                tempData = orderBy(tempData, options.sortBy[0].id, options.sortBy[0].desc ? 'desc' : 'asc');
            }

            //console.info(JSON.stringify(tempData));
            setItems(tempData);
            setRowCount(filterData.length);
            setLoading(false);
        }

    }, []);

    return (
        //奇妙に見えるが、以下の"<>…</>は、JSXの「トップ要素は1つだけ」を満たすための頻出テクニック
        <>
            <Table
                actionGroup={tableActions}
                tableTitle={I18n.get('Update List')}
                errorText={I18n.get('Something went wrong. Please try again later.')}
                items={items}
                onFetchData={handleFetchData} //テーブルオプションが変更されたら毎回呼び出される。テーブルオプションはハンドラ関数の引数として渡される
                onSelectionChange={handleSelectionChange}
                defaultPageSize={50}
                columnDefinitions={columnDefinitions}
                multiSelect={true}
                rowCount={rowCount}
                loading={loading}
                pageSizes={[10, 25, 50, 100]}
                disableGroupBy={false}
                disableSettings={false}
                disablePagination={false}
                disableFilters={false}
                disableRowSelect={false}
                disableSortBy={false}
            />
        </>
    );
}