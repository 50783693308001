
/**
 * 使い方は、Reactコンポーネント内で、useEffect()内で普通に関数を呼び出して、API結果を useStateで作っておいたステートセット関数に食わせる形。
 * React Hooks で画面を表示したときに一度だけ処理を行いたいときには useEffect()を使い、その引数に処理を行う関数オブジェクトを与える。
 * その関数内では、useStateで作り出したステート更新メソッドを呼び出すことができる。
 * 
 * export const TodoBoard: React.FC = () => {
 * 
 *  const [todos, setTodos] = useState<Todo[]>([]);
 * 
 *  useEffect(() => {
 *    BackendService.getTodos()
 *      .then(response => setTodos(response));
 *  }, []);
 */
import { Logger } from 'aws-amplify';
//import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { API } from 'aws-amplify';
import { graphqlOperation, GraphQLResult } from '@aws-amplify/api-graphql'
import gql from 'graphql-tag';
import { Auth } from 'aws-amplify';
import * as GraphQLTypes from './graphql-types';
import * as GraphQLQueries from '../graphql/queries';

//Logger
const logger = new Logger('services');

// 注意：インスタンスは使い回し可能 ※中位：AWSAppSyncClientを使うと、コンパイルに失敗する
//      なので以下のコーディングを使おうとしてはならない
// const appsyncClient = new AWSAppSyncClient({
//     url: 'https://so4hzfrkxnce7jhtjq7l7trjxm.appsync-api.us-west-2.amazonaws.com/graphql',
//     region: 'us-west-2',
//     auth: {
//         type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
//         jwtToken: async () => (await Auth.currentSession()).getAccessToken().getJwtToken(),
//     },
//     disableOffline: true,
// });

const listUpdates = async () => {

    try {
        // const result = await appsyncClient.query(
        //     {
        //         fetchPolicy: 'network-only',
        //         query: gql(GraphQLQueries.query)
        //     });

        // const result = await API.graphql(graphqlOperation(GraphQLQueries.all)) as GraphQLResult<GraphQLTypes.AllQuery>;
        const result = await API.graphql({
            query: GraphQLQueries.all,
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        }) as GraphQLResult<GraphQLTypes.AllQuery>;

        if ('data' in result && result.data) {
            const updates = result.data as GraphQLTypes.AllQuery;
            //const updates : any = result.data;
            if (updates.all) {
                logger.info(JSON.stringify(updates.all));
                return updates.all;
            }
        }
        throw new Error('Invalid response. Cannot retrieve data: ' + JSON.stringify(result));
    } catch (err) {
        logger.error(err);
        throw err;
    }
    // const query = gql(`

    // `);
    // try {
    //     const {data} = await appsyncClient.query({
    //         fetchPolicy: 'network-only',
    //         query: gql(GraphQLQueries.queryAllUpdates),
    //         //variables: { id }
    //     });

    //     logger.info(JSON.stringify(data));

    // } catch (e) {
    //     //
    //     logger.error(JSON.stringify(e));
    //     throw e;
    // }
};

/**
 * クラスではない。ので実装は不要で、importする側 = 利用側は、単にstaticメソッド的に関数を呼び出す
 */
export const UpdatesService = {
    //ここに関数オブジェクトを列挙(その変数名がキー、値が関数オブジェクトになる)
    listUpdates,
    // signup,
    // login,
    // logout,
    // getTodos,
    // postTodo,
    // putTodo,
    // refreshCsrfToken
};