/**
 * 多言語対応定義 (Amplify I18nライブラリ仕様)
 */
export const i18n_dict = {
    'ja': {
        'Back to Sign In': 'サインイン画面に戻る',
        'Confirm': '確認',
        'Confirm Sign Up': 'サインアップの確認',
        'Confirmation Code': '確認コード',
        'Create Account': '新規登録',
        'Create a new account': 'アカウントの新規登録',
        'Create account': '新規登録',
        'Confirm Password': 'パスワード(確認)',
        'Email': 'メールアドレス',
        'Email Address *': 'メールアドレス *',
        'Enter your code': '確認コードを入力してください',
        'Enter your password': 'パスワードを入力してください',
        'Enter your username': 'ユーザー名を入力してください',
        'Enter your email address': 'メールアドレスを入力してください',
        'Forget your password? ': 'パスワードをお忘れの方 ',
        'Forgot your password?': 'パスワードをお忘れの方 ',
        'Have an account? ': 'アカウント登録済みの方 ',
        'Have an account?': 'アカウント登録済みの方 ',
        'Hello': 'こんにちは ',
        'Incorrect username or password': 'ユーザー名またはパスワードが異なります',
        'Lost your code? ': 'コードを紛失した方 ',
        'No account? ': 'アカウント未登録の方 ',
        'No account?': 'アカウント未登録の方 ',
        'Password': 'パスワード',
        'Password ': 'パスワード',
        'Password *': 'パスワード *',
        'Phone Number': '電話番号',
        'Phone Number *': '電話番号',
        'Resend Code': '確認コードの再送',
        'Reset password': 'パスワードのリセット',
        'Reset your password': 'パスワードのリセット',
        'Send Code': 'コードの送信',
        'Sign In': 'サインイン',
        'Sign Out': 'サインアウト',
        'Sign in': 'サインイン',
        'Sign in to your account': 'サインイン',
        'Signing in': 'サインイン中',
        'User does not exist': 'ユーザーが存在しません',
        'Username': 'ユーザー名',
        'Username cannot be empty': 'ユーザー名は必須入力です',
        'Username/client id combination not found.': 'ユーザー名/クライアントIDのいずれかが見つかりません',
        'User does not exist.': 'ユーザー名が見つかりません',
        'Incorrect username or password.' : 'ユーザー名またはパスワードが誤りです',
        'Something went wrong. Please try again later.': '何か問題が発生しています。後ほど再実行してください。',
        'Add': '追加',
        'Delete': '削除',
        'Category':'カテゴリ',
        'Title':'タイトル',
        'Published Date': '公開日',   
        'Comment': 'コメント',
        '(None)': '-',
        'Update': '更新',
        'Menu': 'メニュー',
        'Update List' : 'アップデート 一覧',
        'Update Visualize': 'アップデート 可視化',
        'Report Generate':'レポート 生成',
        'Generate':'生成',
        'Categorized to' : 'セット先カテゴリー',
        'Change Category' : 'カテゴリーを割当',
        'Start Date':'開始日',
        'End Date':'終了日',
        'Sort Order in Category Slides': 'カテゴリスライドでのソート順序',
        'Date(Asc)': '日付(昇順)',
        'Service(Dic), Date(Asc)': 'サービス名(辞書順), 日付(昇順)',
        'Please select order pattern': 'ソート順序を指定して下さい (デフォルト:「サービス名(辞書順), 日付(昇順)」)',
        'Updates Summary Report': 'アップデートサマリーレポート',
        'Blogs Summary Report': 'Blogサマリーレポート',
    }
};