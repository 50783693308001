import { Column, ColumnLayout, Container, KeyValuePair, Stack, StatusIndicator } from 'aws-northstar';
import logo from '../logo.svg';
/**
 * 関数コンポーネントの体裁としているが、特に追加の処理はしていないので、JSXテキストだけセットする格好でも問題無い
 */
export const SampleContent: React.FC = () => {

  //
  return (
    //JSXは唯一の親要素が必要である
    <Container headingVariant="h4" title="Application Information">

      <ColumnLayout>
        <Column key="col1">
          <Stack>
            <KeyValuePair label="Owner" value="matsubta" />
          </Stack>
        </Column>
        <Column key="col2">
          <Stack>
            <KeyValuePair label="Created at" value={new Date().toLocaleDateString()} />
            <KeyValuePair label="Status" value={<StatusIndicator statusType="positive">Running</StatusIndicator>} />
          </Stack>
        </Column>
      </ColumnLayout>
    </Container>
  );
};

