import { Container, KeyValuePair, Stack, StatusIndicator } from 'aws-northstar';
import { useMemo, useContext, useState, useEffect, useCallback, useRef, ChangeEvent, ReactNode } from 'react';
import { I18n, Logger, API } from 'aws-amplify'
import FormField from 'aws-northstar/components/FormField';
import DatePicker from 'aws-northstar/components/DatePicker';
import Tabs from 'aws-northstar/components/Tabs';
import Button from 'aws-northstar/components/Button';
import Select, { SelectOption } from 'aws-northstar/components/Select';
import { GenerateReportService } from '../services/generate-report-service';
import { AppContext, AppContextType } from '../components/app-context';
import SortOrderInCategorySlidesOptions from '../constants/sort-orders-in-category-slides';

/**
 * 
 * @returns 
 */
export const ReportGeneratePage: React.FC = () => {

    const logger = new Logger('ReportGeneratePage');
    const appContext: AppContextType = useContext(AppContext);

    //初期値設定
    let startdt = new Date();
    let enddt = new Date();
    //開始日として、先月の初日をセット
    startdt.setMonth(startdt.getMonth() - 1)
    startdt.setDate(1);
    formatDateToString(startdt)

    //終了日として、先月の月末日をセット
    enddt.setMonth(enddt.getMonth() + 0);//今月に一旦セット
    enddt.setDate(0); //日付に0を設定し、該当月の0日（つまり、前月の月末）にセット

    //レポートボタン活性化フラグ
    const [reportDisabled, setReportDisabled] = useState(false);

    //アップデートサマリータブ内の項目
    let [startDateUpdates, setStartDateUpdates] = useState(startdt);
    let [endDateUpdates, setEndDateUpdates] = useState(enddt);

    //ブログサマリータブ内の項目
    let [startDateBlogs, setStartDateBlogs] = useState(startdt);
    let [endDateBlogs, setEndDateBlogs] = useState(enddt);

    //プルダウン(カテゴリスライド内のソート基準)
    const [selectedSortOrder, setSelectedSortOrder] = useState('service_dic-date_asc');

    //ソート順選択オプション状態
    const [selectedSortOrderInCategorySlidesOption, setSelectedSortOrderInCategorySlidesOption] = useState<SelectOption>();

    //
    const sortOrderChange = (event: any, child: ReactNode) => {
        setSelectedSortOrderInCategorySlidesOption(SortOrderInCategorySlidesOptions.find((o: any) => (o.value === event.target.value)));
        console.info("selected sortOrder Value:" + event.target.value);
        setSelectedSortOrder(event.target.value as string);
    };

    const reportUpdatesSummaryCallback = useCallback(() => {
        //if (startDateUpdates == 'INVALID' || endDateUpdates == 'INVALID') {
        if (!startDateUpdates || !endDateUpdates) {
            appContext.addFlashbarMessage({
                header: 'エラー',
                dismissible: true,
                type: 'error',
                content: `開始日・終了日に正しい日付が設定されていません。`,
            });
            return;
        } else if (!isValidRelations(startDateUpdates, endDateUpdates)) {
            appContext.addFlashbarMessage({
                header: 'エラー',
                dismissible: true,
                type: 'error',
                content: `開始日・終了日の前後関係が不正です。`,
            });
            return;

        } else if (isDurationExceeded(startDateUpdates, endDateUpdates)) {
            appContext.addFlashbarMessage({
                header: 'エラー',
                dismissible: true,
                type: 'error',
                content: `生成対象期間は最大で100日以内です。`,
            });
            return;
        }
        appContext.clearFlashbarMessages();
        setReportDisabled(true);
        GenerateReportService.generateUpdatesSummaryReport(appContext, { 'startDate': formatDateToString(startDateUpdates), 'endDate': formatDateToString(endDateUpdates), 'sortOrder': selectedSortOrder })
            .finally(() => { setReportDisabled(false); });
    }, [startDateUpdates, endDateUpdates, selectedSortOrder]); //この配列内の依存は必須。でないと新しい値でこの関数は実行されない(初期値のstartDateとendDateのままでGenerateReportService.generateSummaryReportが実行されてしまう)

    const reportBlogsSummaryCallback = useCallback(() => {
        //if (startDateUpdates == 'INVALID' || endDateUpdates == 'INVALID') {
        if (!startDateUpdates || !endDateUpdates) {
            appContext.addFlashbarMessage({
                header: 'エラー',
                dismissible: true,
                type: 'error',
                content: `開始日・終了日に正しい日付が設定されていません。`,
            });
            return;
        } else if (!isValidRelations(startDateUpdates, endDateUpdates)) {
            appContext.addFlashbarMessage({
                header: 'エラー',
                dismissible: true,
                type: 'error',
                content: `開始日・終了日の前後関係が不正です。`,
            });
            return;

        } else if (isDurationExceeded(startDateUpdates, endDateUpdates)) {
            appContext.addFlashbarMessage({
                header: 'エラー',
                dismissible: true,
                type: 'error',
                content: `生成対象期間は最大で100日以内です。`,
            });
            return;
        }
        appContext.clearFlashbarMessages();
        setReportDisabled(true);
        GenerateReportService.generateBlogsSummaryReport(appContext, { 'startDate': formatDateToString(startDateBlogs), 'endDate': formatDateToString(endDateBlogs), })
            .finally(() => { setReportDisabled(false); });
    }, [startDateBlogs, endDateBlogs]); //この配列内の依存は必須。でないと新しい値でこの関数は実行されない(初期値のstartDateとendDateのままでGenerateReportService.generateSummaryReportが実行されてしまう)


    //レポートアクショングループJSXコンポーネント
    const reportUpdatesActions = (

        <Button variant='primary' onClick={reportUpdatesSummaryCallback} disabled={reportDisabled}>{I18n.get('Generate')}
        </Button>
    );

    const reportBlogsActions = (

        <Button variant='primary' onClick={reportBlogsSummaryCallback} disabled={reportDisabled}>{I18n.get('Generate')}
        </Button>
    );


    const updatesSummaryTab = {
        label: I18n.get('Updates Summary Report'),
        id: 'updatesSummaryReportTab',
        content: (
            <Container headingVariant="h4" title={I18n.get('Report Generate')} actionGroup={reportUpdatesActions}>
                <FormField
                    label={I18n.get('Start Date')}
                    controlId="startdate"
                >
                    <DatePicker controlId="startdate" label="StartDate" value={startDateUpdates} onChange={(changedDate: Date | null | undefined) => {
                        const date: string = formatDateToString(changedDate);
                        console.log('setStartDate = ' + date);
                        if (changedDate) {
                            setStartDateUpdates(changedDate);
                        }
                    }}></DatePicker>
                </FormField>
                <FormField
                    label={I18n.get('End Date')}
                    controlId="enddate"
                >
                    <DatePicker controlId="enddate" label="EndDate" value={endDateUpdates} onChange={(changedDate: Date | null | undefined) => {
                        const date: string = formatDateToString(changedDate);
                        console.log('setEndDate = ' + date);
                        if (changedDate) {
                            setEndDateUpdates(changedDate);
                        }
                    }}></DatePicker>
                </FormField>
                <FormField label={I18n.get('Sort Order in Category Slides')}
                    controlId="sortorder">
                    <Select
                        controlId="sortorder"
                        placeholder={I18n.get('Please select order pattern')}
                        options={SortOrderInCategorySlidesOptions}
                        selectedOption={selectedSortOrderInCategorySlidesOption}
                        onChange={sortOrderChange}
                    ></Select>
                </FormField>
            </Container>
        )
    };

    const blogsSummaryTab = {
        label: I18n.get('Blogs Summary Report'),
        id: 'blogsSummaryReportTab',
        content: (
            <Container headingVariant="h4" title={I18n.get('Report Generate')} actionGroup={reportBlogsActions}>
                <FormField
                    label={I18n.get('Start Date')}
                    controlId="startdateUpdates"
                >
                    <DatePicker controlId="startdateUpdates" label="StartDate" value={startDateBlogs} onChange={(changedDate: Date | null | undefined) => {
                        const date: string = formatDateToString(changedDate);
                        console.log('setStartDate = ' + date);
                        if (changedDate) {
                            setStartDateBlogs(changedDate);
                        }
                    }}></DatePicker>
                </FormField>
                <FormField
                    label={I18n.get('End Date')}
                    controlId="enddateUpdates"
                >
                    <DatePicker controlId="enddateUpdates" label="EndDate" value={endDateBlogs} onChange={(changedDate: Date | null | undefined) => {
                        const date: string = formatDateToString(changedDate);
                        console.log('setEndDate = ' + date);
                        if (changedDate) {
                            setEndDateBlogs(changedDate);
                        }
                    }}></DatePicker>
                </FormField>
            </Container>
        )
    }

    const tabs = [updatesSummaryTab, blogsSummaryTab];

    return (
        //JSXは唯一の親要素が必要である
        <Tabs tabs={tabs} variant="container" />
    );
}

/**
 * 日付文字列の前後判定を行う関数
 * @param startdt 
 * @param enddt 
 * @returns 
 */
function isValidRelations(startdt: Date, enddt: Date): boolean {
    //const start: number = Date.parse(startdt);
    //const end: number = Date.parse(enddt);

    return (enddt >= startdt);
}

/**
 * 日付期間の判定を行う関数 (現状100日を超えるとエラー)
 * @param startdt 
 * @param enddt 
 * @returns 
 */
function isDurationExceeded(startdt: Date, enddt: Date): boolean {
    const start: number = startdt.getTime();
    const end: number = enddt.getTime();

    //差日を求める（86,400,000ミリ秒=1日）
    const termDay = (end - start) / 86400000;
    return termDay > 100;
}

/**
 * 日付をYYYY-MM-DDの書式で返す関数
 * @param dt 
 * @returns 
 */
function formatDateToString(dt: Date | null | undefined): string {

    if (dt === null || dt == undefined || dt.toString() === "Invalid Date") {
        //日付データとして成立していない場合、trueになる。
        //その場合は 内部予約のキーワード文字を返す
        return 'INVALID';
    }

    var y = dt.getFullYear();
    var m = ('00' + (dt.getMonth() + 1)).slice(-2);
    var d = ('00' + dt.getDate()).slice(-2);
    return (y + '-' + m + '-' + d);
}

