/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const all = /* GraphQL */ `
  query All {
    all {
      id
      published_epochtime
      published_date
      comment
      created_by
      created_epochtime
      link
      category
      predicted_category
      title_ja
      summary_ja
      updated_by
    }
  }
`;
export const query = /* GraphQL */ `
  query Query($category: String!, $datestring: String!) {
    query(category: $category, datestring: $datestring) {
      id
      published_epochtime
      published_date
      comment
      created_by
      created_epochtime
      link
      category
      predicted_category
      title_ja
      summary_ja
      updated_by
    }
  }
`;
