//アップデートアイテムのカテゴリ選択オプション状態
const ItemCategoryOptions = [
    { label: 'Compute', value: 'compute' },
    { label: 'Containers', value: 'containers' },
    { label: 'Storage', value: 'storage' },
    { label: 'Database', value: 'databases' },
    { label: 'Migration Transfer', value: 'migration-transfer' },
    { label: 'Networking & Content Delivery', value: 'networking-and-content-delivery' },
    { label: 'Messaging', value: 'messaging' },
    { label: 'Developer Tools', value: 'developer-tools' },
    { label: 'Robotics', value: 'robotics' },
    { label: 'Customer Enablement', value: 'customer-enablement' },
    { label: 'Blockchain', value: 'blockchain' },
    { label: 'Application Services', value: 'application-services' },
    { label: 'Quantum Technologies', value: 'quantum-technologies' },
    { label: 'Management & Governance', value: 'management-and-governance' },
    { label: 'Media Services', value: 'media-services' },
    { label: 'AI/ML', value: 'artificial-intelligence' },
    { label: 'Security, Identity & Compliance', value: 'security-identity-and-compliance' },
    { label: 'AR&VR', value: 'ar-and-vr' },
    { label: 'Analytics', value: 'analytics' },
    { label: 'Application Integration', value: 'application-integration' },
    { label: 'Cost Management', value: 'cost-management' },
    { label: 'Customer Engagement', value: 'customer-engagement' },
    { label: 'Business Applications', value: 'business-applications' },
    { label: 'Business Productivity', value: 'business-productivity' },
    { label: 'Internet of Things', value: 'internet-of-things' },
    { label: 'Game Tech', value: 'game-tech' },
    { label: 'Training & Certification', value: 'training-and-certification' },
    { label: 'AWS Marketplace & Partners', value: 'aws-marketplace-and-partners' },
    { label: 'Mobile Services', value: 'mobile-services' },
    { label: 'Satellite', value: 'satellite' },
    { label: 'Solutions', value: 'solutions' },
    { label: 'Other', value: 'OTHER' },
    { label: 'None', value: 'NONE' },
];

/**
 * 
 * @param value 
 */
export const getCategoryLabelFrom = (value: string): string => {

    if (!value) {
        return 'None';
    }
    const matched = ItemCategoryOptions.find((e) => value === e.value);

    if (matched != undefined && matched.label) {
        return (matched.label || 'None');  //合致しなければNONE
    } else {
        return 'None';
    }
}

export default ItemCategoryOptions;