import React, { FC, useMemo, useContext } from 'react';

import AppLayoutBase from 'aws-northstar/layouts/AppLayout';
import HeaderBase from 'aws-northstar/components/Header';
import SideNavigationBase, { SideNavigationItemType } from 'aws-northstar/components/SideNavigation';
import BreadcrumbGroup from 'aws-northstar/components/BreadcrumbGroup';
import HelpPanel from 'aws-northstar/components/HelpPanel';
import Link from 'aws-northstar/components/Link';
import Text from 'aws-northstar/components/Text';
import Flashbar from 'aws-northstar/components/Flashbar';

import { I18n } from 'aws-amplify'

import { AppContext, AppContextType, AppContextProvider } from './app-context';


/**
 * 
 * @param param
 */
const AppLayout: FC = ({ children }) => {

    const appContext: AppContextType = useContext(AppContext);

    const Header = useMemo(() => (
        <HeaderBase title='AWS Updates Visualizer' />
    ), []);

    const Breadcrumbs = useMemo(() =>
        <BreadcrumbGroup rootPath="Home" />, []
    );

    const SideNavigation = useMemo(() => {
        return <SideNavigationBase
            header={{
                href: '/',
                text: I18n.get('Menu'),
            }}
            items={[
                { type: SideNavigationItemType.LINK, text: I18n.get('Update List'), href: '/updates-list' },
                { type: SideNavigationItemType.LINK, text: I18n.get('Update Visualize'), href: '/updates-visualize' },
                { type: SideNavigationItemType.LINK, text: I18n.get('Report Generate'), href: '/report-generate' },
                { type: SideNavigationItemType.DIVIDER },
                // {
                //   type: SideNavigationItemType.LINK,
                //   text: 'Notifications',
                //   href: '/notifications',
                //   info: <Badge color="red" content="23"></Badge>,
                // },
                {
                    type: SideNavigationItemType.LINK,
                    text: "AWS What's New",
                    href: 'https://aws.amazon.com/jp/new/',
                }
            ]}
        ></SideNavigationBase>
    }, []); //依存なし(空配列を第2引数に指定することで、再レンダリング対象から結果的に除外)

    const helpPanel = useMemo(() => {
        return <HelpPanel
            header="ヘルプ"
            learnMoreFooter={[
                <Link href="https://aws.amazon.com/jp/new/">AWS最新情報</Link>,
            ]}
        >
            <Text variant="p">
                デモアプリケーションです。
        </Text>
        </HelpPanel>
    }, [])//依存なし(空配列を第2引数に指定することで、再レンダリング対象から結果的に除外)

    //Flashbarを、AppLaoutBaseの上に持ってくることで、タイトルスクロールせずとも必ず画面に表示・視認できるようにする。
    return <>
        <AppLayoutBase
            header={Header}
            navigation={SideNavigation}
            breadcrumbs={Breadcrumbs}
            helpPanel={helpPanel}
        >
            <Flashbar items={appContext.flashbarItems} />
            {children}
        </AppLayoutBase>
    </>
}

export default AppLayout;