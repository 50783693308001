import React, { useContext, useState, useRef, useEffect, createContext, ChangeEvent, ReactNode } from 'react';
import Flashbar, { FlashbarMessage } from 'aws-northstar/components/Flashbar';

// AppContext が保持する値の型
export interface AppContextType {
  flashbarItems: FlashbarMessage[];
  addFlashbarMessage: (message: FlashbarMessage) => void;
  clearFlashbarMessages: () => void;
}


// AppContext の生成
export const AppContext = React.createContext<AppContextType>(
  //初期値オブジェクト
  {
    flashbarItems: [],  // デフォルト値
    addFlashbarMessage: (message: FlashbarMessage) => { }, //ダミー関数
    clearFlashbarMessages: () => { },
  }
);


/**
 * AppContext にセッター関数を登録するためのコンポーネント
 */
export const AppContextProvider: React.FC = ({ children }) => {

  // デフォルト値の取得用
  const context: AppContextType = useContext(AppContext);

  // ステートオブジェクト作成
  const [flashbarItems, setFlashbarItems] = useState(context.flashbarItems);

  /**
   * 
   * @param message 
   */
  const addFlashbarMessage = (message: FlashbarMessage) => {
    console.info('addFlashbarMessage called!');
    if (message != null) {
      //ここで   items.push(message); してもう一度それをsetItemsしても、Reactは同一オブジェクトとして判定するので
      //描画の再更新が行われない! → 以下のような新規配列オブジェクトを生成して設定する
      //追加は以下のようなイディオムを用いて、削除は .filterメソッドを使うことで新しい配列を生成する
      message.onDismiss = () => {
        const idx = flashbarItems.indexOf(message);
        const newArray = flashbarItems.filter(n => idx != -1);
        setFlashbarItems(newArray);
      };
      let newArray = [...flashbarItems, message];
      console.info(newArray);
      setFlashbarItems(newArray);
    }
  };

  /**
   * 
   */
  const clearFlashbarMessages = ()=> {
    console.info('clearFlashbarMessages called!');
    setFlashbarItems([]);
  }

  // 下位コンポーネントへ渡す Context
  const newContext: AppContextType = {
    flashbarItems,
    addFlashbarMessage,
    clearFlashbarMessages,
  };

  return (
    <AppContext.Provider value={newContext}>
      {children}
    </AppContext.Provider>
  );
};


