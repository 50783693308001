import React, { FC, ComponentType, useRef, useContext, useEffect, createContext, ChangeEvent, ReactNode } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';

import { withAuthenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css';
import { I18n } from 'aws-amplify'
import Amplify, { Auth, API } from 'aws-amplify';
import awsconfig from './aws-exports';


import { i18n_dict } from './constants/i18n-dict';

import NorthStarThemeProvider from 'aws-northstar/components/NorthStarThemeProvider';

import { SampleContent } from './pages';
import { UpdatesListPage } from './pages';
import { UpdatesVisualizePage } from './pages';
import { ReportGeneratePage } from './pages';
import { AppContext, AppContextType, AppContextProvider } from './components/app-context';
import AppLayout from './components/app-layout';

API.configure({
  //API GatewayのAPI(のエンドポイント)に対して論理名を付与して登録
  endpoints: [
    //QuickSightの埋め込みAPI
    {
      name: "GetQuickSightDashboardEmbedUrlAPI",
      endpoint: "https://bnzd3amagi.execute-api.us-west-2.amazonaws.com",
      custom_header: async () => {
        //return { Authorization : 'token' } 
        // Alternatively, with Cognito User Pools use this:
        //return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
        return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
      }
    },
    //サマリレポートAPI。REST API側が、Cognito Authorizerが認証設定されているので、ヘッダでIDトークンを送る
    {
      name: "SummaryReportPptxGeneratorAPI",
      endpoint: "https://npx0f3m0ed.execute-api.us-west-2.amazonaws.com",
      custom_header: async () => {
        //return { Authorization : 'token' } 
        // Alternatively, with Cognito USummaryReportPptxGeneratorser Pools use this:
        //return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
        return {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          Accept: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        }
      }
    },
  ]
});


//自動生成でamplify add や remove を行うと内容が完全上書きされる aws-exports.js ファイルではなく、
//マージする形で、AppSyncのAPIエンドポイント(Amplifyアプリケーション全体で1つしか指定できないことに注意)を設定する
const myappconfig = {
  ...awsconfig, ...{
    //このAppSyncのGraphQLのAPIエンドポイントは、CDKの ComputeServerlessStackをデプロイした際に決定するものを
    //人間的にコピペしてきている。なのでそれが何らかの理由で再作成されてエンドポイントが変わったらこちらも追随して更新する必要がある
    'aws_appsync_graphqlEndpoint': 'https://inormyhy6zhmjnsotbnsmqcwdm.appsync-api.us-west-2.amazonaws.com/graphql',
    'aws_appsync_region': 'us-west-2',
    'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS', // You have configured Auth with Amazon Cognito User Pool ID and Web Client Id
  }
};

// Amplifyライブラリの構成をロード
Amplify.configure(myappconfig);

const withLayout = (Component: ComponentType): FC => (props) => (
  <AppLayout>
    <Component {...props} />
  </AppLayout>
);

// React 17では、16系の「const App: React.FC<unknown> = () => { return ( ... ); }」 というReact.FCクラスはもう使わない
function App() {
  return (
    // <NorthStarThemeProvider>は、BrowserRouterとの入れ子順番は逆転してもOK
    // また、そもそもindex.tsx側での <App />の外側に配置しても問題は無い
    // なお、JSXは単一の親要素で表記が必要(並列に並んだトップ要素を複数持つことはできない)
    <NorthStarThemeProvider>
      <BrowserRouter>
        <AppContextProvider>
          <Switch>
            <Route exact path="/" component={withLayout(SampleContent)}></Route>
            <Route exact path="/updates-list" component={withLayout(UpdatesListPage)}></Route>
            <Route exact path="/updates-visualize" component={withLayout(UpdatesVisualizePage)}></Route>
            <Route exact path="/report-generate" component={withLayout(ReportGeneratePage)}></Route>
            <Route exact path="/demo" render={() => {
              return (<Redirect to="/list-updates" />)
            }}>
            </Route>
          </Switch>
        </AppContextProvider>

      </BrowserRouter>
    </NorthStarThemeProvider>
  );
}

//export default App; //withAuthenticatorにより、アプリは未ログインと見なされたら自動でAmplify提供のログイン画面が表示される動きになる
//なお、CSSを 「import '@aws-amplify/ui-react/styles.css';」でインポートしていないとそのログイン画面に修飾がされない。
export default withAuthenticator(App);

// Amplify Authenticatorライブラリで日本語化を行うための指定
I18n.putVocabularies(i18n_dict);
I18n.setLanguage('ja');