import { Logger } from 'aws-amplify';
import { useContext } from 'react';
import { API } from 'aws-amplify';
import { AppContext, AppContextType } from '../components/app-context';
import { saveAs } from 'file-saver'

//Logger
const logger = new Logger('services');


const generateUpdatesSummaryReport = async (appContext: AppContextType, reqParam: { startDate: string, endDate: string, sortOrder: string }) => {


    appContext.addFlashbarMessage({
        header: 'ダウンロード要求中',
        dismissible: false,
        type: 'success',
        content: `しばらくお待ちください…`,
        loading: true,
    });

    await API.get('SummaryReportPptxGeneratorAPI', '/Prod/summary-report-pptx', {
        responseType: 'blob',
        response: true, //trueにすることで、response.dataが本来欲しいデータになる(trueにするとAxiosのresponseオブジェクトがオブジェクトそのもの)
        queryStringParameters: {
            startDate: reqParam.startDate,
            endDate: reqParam.endDate,
            sortOrder: reqParam.sortOrder,
            debug: false,//falseを指定しなければ、サーバ側で一時生成PPTXファイルが削除されないので注意
        },
    }).then((response) => {
        appContext.clearFlashbarMessages();

        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const filename = 'updates-summaryreport-' + reqParam.startDate.replaceAll('-', '') + 'to' + reqParam.endDate.replaceAll('-', '') + '.pptx';
        saveAs(blob, filename);
    }).catch((error) => {
        console.log(error);
    }).finally(() => {
        //
    });

};

const generateBlogsSummaryReport = async (appContext: AppContextType, reqParam: { startDate: string, endDate: string }) => {


    appContext.addFlashbarMessage({
        header: 'ダウンロード要求中',
        dismissible: false,
        type: 'success',
        content: `しばらくお待ちください…`,
        loading: true,
    });

    await API.get('SummaryReportPptxGeneratorAPI', '/Prod/blog-report-pptx', {
        responseType: 'blob',
        response: true, //trueにすることで、response.dataが本来欲しいデータになる(trueにするとAxiosのresponseオブジェクトがオブジェクトそのもの)
        queryStringParameters: {
            startDate: reqParam.startDate,
            endDate: reqParam.endDate,
            debug: false,//falseを指定しなければ、サーバ側で一時生成PPTXファイルが削除されないので注意
        },
    }).then((response) => {
        appContext.clearFlashbarMessages();

        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const filename = 'blogs-summaryreport-' + reqParam.startDate.replaceAll('-', '') + 'to' + reqParam.endDate.replaceAll('-', '') + '.pptx';
        saveAs(blob, filename);
    }).catch((error) => {
        console.log(error);
    }).finally(() => {
        //
    });

};

/**
 * クラスではない。ので実装は不要で、importする側 = 利用側は、単にstaticメソッド的に関数を呼び出す
 */
export const GenerateReportService = {
    //ここに関数オブジェクトを列挙(その変数名がキー、値が関数オブジェクトになる)
    generateUpdatesSummaryReport,
    generateBlogsSummaryReport,
    // signup,
    // login,
    // logout,
    // getTodos,
    // postTodo,
    // putTodo,
    // refreshCsrfToken
};